/**
 *
 */
import { useSubscriptionStore } from '@/stores/SubscriptionStore'
// import { storeToRefs } from 'pinia'

const subscription = useSubscriptionStore()
// const { price } = storeToRefs(useSubscriptionStore())

export default {
  methods: {

    // Admin function. Saves to or gets prices from ocpu
    managePrices (item) {
      // subscription.$patch((state) => {
      //   state.aSub = item
      // })
      // console.log(item)
      // console.log(this.path)
      const ocpuPack = this.$store.state.ocpu.ocpuPack
      return new Promise((resolve, reject) => {
        this.$httpOcpu
          .post(`/${ocpuPack}/R/managcapiprice/json`, { type: 'post', price: JSON.stringify(subscription.price) })
          .then(response => {
            // console.log(response)
            if (response.status === 201) {
              resolve(response)
            }
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      })
      // this.$router.push({ name: this.path })
    },

  },
}
