<template>
  <v-card
    :color="!plan.best ? 'grey lighten-4 elevation-1' : undefined"
    :raised="plan.best"
    outlined
    class="v-card--plan mx-auto pt-3 pb-4 px-2 text-center"
    max-width="100%"
  >
    <div
      class="body-2 text-uppercase grey--text"
      v-text="$t(plan.heading)"
    />

    <v-avatar
      size="130"
    >
      <v-icon
        :color="!plan.best ? undefined : 'primary'"
        size="64"
        v-text="plan.icon"
      />
    </v-avatar>

    <h2
      class="display-2 font-weight-light"
      v-text="$t(plan.title)"
    />
    <h3
      class="display-1 font-weight-light"
    >
      <div class="mt-2 font-weight-light">
        {{ $t('common.projects') }}: {{ $t(plan.projects) }}
      </div>
      <div class="my-2 font-weight-light">
        {{ $t('common.forms') }}: {{ $t(plan.forms) }}
      </div>
    </h3>

    <v-card-text
      class="body-1 font-weight-light pa-1"
      v-text="$t(plan.text)"
    />

    <pages-btn
      :color="!plan.best ? 'white' : 'primary'"
      :to="to"
      :disabled="disabled"
      @click="$emit('selected', $event)"
    >
      {{ $t(btntitle) }}
    </pages-btn>
  </v-card>
</template>

<script>
  export default {
    name: 'PagesPlanCard',

    components: {
      PagesBtn: () => import('./Btn'),
    },

    props: {
      plan: {
        type: Object,
        default: () => ({
          best: false,
          heading: undefined,
          icon: undefined,
          title: undefined,
          text: undefined,
        }),
      },
      btntitle: {
        type: String,
        default: '',
      },
      to: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },

    computed: {},

  }
</script>

<style lang="sass">
  .v-card--plan
    .v-avatar
      border-radius: 50%
      border: 1px solid #E5E5E5
</style>
