<template>
  <base-section
    id="plans"
  >
    <!--
    <v-img
      :src="require('@/assets/logo.svg')"
      class="mx-auto mb-8"
      max-width="128"
    />
    -->

    <base-section-heading title="Pick the best plan for you">
      <div v-html="$t('landing-plans.text')" />
    </base-section-heading>
    <v-row
      align="center"
      justify="center"
    >
      <v-btn-toggle
        v-model="pricingType"
        mandatory
        rounded
        color="primary"
        @change="pricingMode()"
      >
        <v-btn
          text
        >
          Yearly
        </v-btn>
        <v-btn text>
          Monthly
        </v-btn>
      </v-btn-toggle>
    </v-row>

    <v-container>
      <v-row
        v-if="!dataloaded"
        align="center"
        justify="center"
      >
        <template v-for="n in 3">
          <v-col
            :key="n"
            cols="12"
            lg="3"
            md="6"
          >
            <v-skeleton-loader
              v-if="!dataloaded"
              v-bind="attrs"
              type="card-heading, image, list-item-three-line, actions"
            />
          </v-col>
        </template>
      </v-row>
      <v-row
        v-else
        align="center"
        justify="center"
      >
        <template v-for="(plan, i) in pricing">
          <v-col
            :key="i"
            cols="12"
            lg="3"
            md="6"
          >
            <pages-plan-card
              :plan="plan"
              :btntitle="buttonTitle(plan)"
              :disabled="disabled"
              @selected="selected(plan)"
            />
          </v-col>
        </template>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import PagesPlanCard from '@/components/PlanCard'
  import userAccess from '@/mixins/user-access'
  import payment from '@/mixins/payment'
  import admin from '@/mixins/admin'
  import { useSubscriptionStore } from '@/stores/SubscriptionStore'
  // import { storeToRefs } from 'pinia'

  // const { price } = storeToRefs(useSubscriptionStore())
  const subscription = useSubscriptionStore()

  export default {
    name: 'SectionPlans',

    components: {
      PagesPlanCard,
    },

    mixins: [
      userAccess,
      payment,
      admin,
    ],

    data: () => ({
      paymentType: 'yearly',
      pricingType: 0,
      path: '',
      disabled: false,
      dataloaded: false,
      attrs: {
        class: 'mt-6 pa-4',
        boilerplate: true,
        elevation: 2,
      },
    }),

    computed: {

      pricing () {
        return subscription.pricing
      },
    },

    created () {
      // get price information from ocpu
      subscription.calls.price ? this.getPrices() : this.dataloaded = true
    },

    methods: {
      buttonTitle (plan) {
        // switch (plan.code) {
        //   case 'freelancer':
        //     return this.$store.getters['auth/authorized'] ?
        //     break;
        //   default:
        //     break;
        // }
        if (this.$store.getters['auth/authorized']) {
          this.path = 'Payment'
          this.disabled = !this.isOwner()
          return 'landing-compare.current-subscription'
        } else {
          this.path = 'Login'
          return 'landing-compare.trial'
        }
      },

      pricingMode () {
        subscription.pricingType = this.pricingType
      },

      selected (item) {
        // subscription.$patch((state) => {
        //   state.aSub = item
        // })
        // console.log(item)
        // console.log(this.path)
        this.$router.push({ name: this.path })
        // const ocpuPack = this.$store.state.ocpu.ocpuPack

        // const main = this.$store.state.main
        // return new Promise((resolve, reject) => {
        //   this.$httpOcpu
        //     .post(`/${ocpuPack}/R/setcapiplan/json`, { orgid: main.orgid, plan: JSON.stringify(item), type: 'trial' })
        //     .then(response => {
        //       // console.log(response)
        //       if (response.status === 201) {
        //         resolve(response)
        //       }
        //     })
        //     .catch(error => {
        //       // console.log(error)
        //       reject(error)
        //     })
        // })
      },

    },
  }
</script>
